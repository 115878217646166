import React from "react"
import styled from "@emotion/styled"

import { Helmet } from "react-helmet"
import Cta from "../components/Cta"

const Container = styled.section`
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
`

const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Secondary = styled.h4`
  font-size: 10rem;
  font-weight: 400;
  text-align: center;
  color: #505050;
`

const Body = styled.p`
  font-size: 1.7rem;
  color: #505050;
`

const NotFoundPage = window => (
  <Container>
    <Helmet>
      <title>404 Page not found</title>
    </Helmet>
    <Content>
      <Secondary>404</Secondary>
      <Body>Oops! Looks like that page doesn't exist.</Body>
      <Cta to="/">Take me home</Cta>
    </Content>
  </Container>
)

export default NotFoundPage
